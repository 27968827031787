<template>
    <div class="p-multiselect-list">
        <multiselect
            :options="optionsAvailable"
            :track-by="trackBy"
            @input="updateSelected"
            @close="close"
            :show-labels="true"
            :multiple="true"
            :close-on-select="closeOnSelect"
            :custom-label="customLabel"
            :placeholder="placeholder"
            :select-label="selectLabel"
            :searchable="true"
            :internal-search="!isRemoteData"
            @search-change="runFetchDataFunction"
            :loading="showLoadingSpinner"
            :show-no-options="showNoOptions"
            :show-no-results="showNoResults"
        >
            <template slot="option" slot-scope="props">
                <slot name="multiselect-option" v-bind:props="props"></slot>
            </template>

            <template slot="noOptions">
                <slot name="no-options" v-translate>Empty list</slot>
            </template>
        </multiselect>

        <CListGroup class="mt-3">
            <CListGroupItem class="d-flex justify-content-between align-items-center p-2"

                            v-for="(resource, index) in selectedResources" :key="index">

                <slot name="list-description" v-bind:item="resource"></slot>

                <div v-if="canRemoveSelected">
                   <!---  <CButton color="info mr-2" size="sm" v-c-tooltip="'Genera checkin'">
                        <CIcon name="cil-basket" class="c-icon-sm"/>
                    </CButton>-->
                    <CButton color="danger" size="sm" @click="removeResource(index)">
                        <CIcon name="cipTrashFull" class="c-icon-sm"/>
                    </CButton>
                </div>
            </CListGroupItem>
        </CListGroup>
    </div>
</template>


<script>
    // Ispirazione da
    // https://medium.com/@hugodesigns/how-to-use-the-most-complete-selecting-solution-for-vue-js-f991b2605364
    // TODO: implementare visualizzazione errori di validazione
    import Multiselect from 'vue-multiselect'

    export default {
        name: "PMultiselectList",

        components: {
            Multiselect,
        },

        props: {
            value: {
                type: Array
            },
            options: {
                type: Array
            },
            trackBy: {
                type: String
            },
            placeholder: {
                type: String
            },
            customLabel: {
                type: Function
            },
            selectLabel: {
                type: String
            },
            fetchDataFunction: {
                type: Function
            },
            fetchDataOnCreated: {
                type: Boolean,
                default: false
            },
            closeOnSelect: {
                type: Boolean,
                default: false
            },
            onCloseSelect: {
                type: Function
            },
            showNoOptions: {
                type: Boolean,
                default: true
            },
            showNoResults: {
                type: Boolean,
                default: true
            },
            canRemoveSelected: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                // optionsProxy: [],
                selectedResources: this.value,
                showLoadingSpinner: false,
            }
        },

        created() {
            if (this.isRemoteData && this.fetchDataOnCreated)
                this.runFetchDataFunction()
        },

        watch: {
            value: function (newOption, oldOption) {
                this.selectedResources = newOption
            }
        },

        computed: {
            isRemoteData() {
                return this.fetchDataFunction !== undefined
            },

            optionsAvailable() {
                /*
                TODO: per migliorare le prestazioni, usare due liste per options e selectedResources
                e spostare l'elemento selezionato da una lista all'altra
                */
                return this.options.filter((opt) => !this.selectedResources.some((sr) => sr.id === opt.id))
            }
        },

        methods: {
            close() {
                this.$emit('close-select')
            },

            updateSelected(value) {
                value.forEach((resource) => {
                    // Adds selected resources to array
                    this.selectedResources.push(resource)
                })
                // Clears selected array
                // This prevents the tags from being displayed
                // this.optionsProxy = []

                // v-model update
                this.$emit('input', this.selectedResources)
            },

            removeResource(index) {
                this.selectedResources.splice(index, 1)
            },

            runFetchDataFunction(query) {
                if (query.length < 3)
                    return false

                if (this.isRemoteData) {
                    this.showLoadingSpinner = true

                    this.fetchDataFunction(query)
                        .then(() => {
                            this.showLoadingSpinner = false
                        })
                }
            }
        },
    }
</script>
