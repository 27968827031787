<template>
    <div>
        <h1 class="main-header">Promo</h1>
        <CCard v-if="hasPerm('pica.change_eventpromocode')">
            <CCardHeader>
                <h5 class="card-header--title" v-translate>Promo codes</h5>

                <div class="card-header-actions">
                    <PButton :loading="savingPromoCodes" class="btn btn-sm btn-primary" @click="savePromoCodes">
                        <translate>Save</translate>
                    </PButton>
                </div>
            </CCardHeader>

            <CCardBody>
                <PPicaCodeSelector v-model="selectedPromoCodes" :read-only="!hasPerm('pica.change_eventpromocode')"/>
            </CCardBody>
        </CCard>

        <CCard v-if="hasPerm('pica.change_eventautocheckincode')">
            <CCardHeader>
                <h5 class="card-header--title" v-translate>Auto checkin codes</h5>

                <div class="card-header-actions">
                    <!--<PButton class="btn btn-info mr-2">Genera checkin</PButton>-->
                    <PButton :loading="savingAutoCheckinCodes" class="btn btn-sm btn-primary" @click="saveAutoCheckinCodes">
                        <translate>Save</translate>
                    </PButton>
                </div>
            </CCardHeader>

            <CCardBody>
                <PPicaCodeSelector v-model="selectedAutoCheckinCodes"/>
            </CCardBody>
        </CCard>
    </div>
</template>


<script>
import PicaServiceService from "@/services/pica_service.service"
import PPicaCodeSelector from "@/components/PPicaCodeSelector"
import PButton from "@/components/PButton"

export default {
    name: "EventPromo",

    components: {
        PPicaCodeSelector,
        PButton
    },

    data() {
        return {
            savingPromoCodes: false,
            selectedPromoCodes: [],

            savingAutoCheckinCodes: false,
            selectedAutoCheckinCodes: []
        }
    },

    mounted() {
        if (this.hasPerm('pica.change_eventpromocode')) {
            PicaServiceService.getPromoCodes(this.$store.getters['event/picaServiceId'])
                .then((response) => {
                    this.selectedPromoCodes = response.data
                })
        }

        if (this.hasPerm('pica.change_eventautocheckincode')) {
            PicaServiceService.getAutoCheckinCodes(this.$store.getters['event/picaServiceId'])
                .then((response) => {
                    this.selectedAutoCheckinCodes = response.data
                })
        }
    },

    methods: {
        savePromoCodes() {
            this.savingPromoCodes = true
            const params = this.selectedPromoCodes.map(i => ({'picacode': i.picacode}))

            PicaServiceService.setPromoCodes(this.$store.getters['event/picaServiceId'], params)
                .then(() => {
                    this.savingPromoCodes = false
                    this.notifyMessage({text: this.$gettext('Promo codes updated correctly'), messageType: 'success'})
                })
        },

        saveAutoCheckinCodes() {
            this.savingAutoCheckinCodes = true
            const params = this.selectedAutoCheckinCodes.map(i => ({'picacode': i.picacode}))

            PicaServiceService.setAutoCheckinCodes(this.$store.getters['event/picaServiceId'], params)
                .then(() => {
                    this.savingAutoCheckinCodes = false
                    this.notifyMessage({
                        text: this.$gettext('Auto checkin codes updated correctly'),
                        messageType: 'success'
                    })
                })
        }
    }
}
</script>