<template>
    <PMultiselectList
        :options="picacodesReleased"
        :value="value"
        @input="setPicacodesReleased"
        track-by="id"
        :placeholder="$gettext('Search by Pica Code or by race number ...')"
        :custom-label="(option) => option.picacode + ' ' + option.race_number"
        select-label="seleziona"
        :fetch-data-function="getPicacodesReleased"
        :close-on-select="true"
        @close-select="emptyOptions"
        :show-no-options="false"
        :show-no-results="false"
    >
        <template slot="multiselect-option" slot-scope="{ props }">
            <div>
                <h6 class="mb-0">{{ props.option.picacode }}</h6>
                <CBadge color="secondary">{{ props.option.race_number }}</CBadge>
            </div>
        </template>

        <template #list-description="{ item }">
            <div>
                <h6 class="mb-0">{{ item.picacode }}</h6>
                <CBadge color="secondary">{{ item.race_number }}</CBadge>
            </div>


        </template>
    </PMultiselectList>
</template>


<script>
import PMultiselectList from "@/components/PMultiselectList"
import PicaServiceService from "@/services/pica_service.service"

export default {
    name: "PPicaCodeSelector",

    components: {
        PMultiselectList,
    },

    props: {
        value: {
            type: Array
        },
    },

    data() {
        return {
            picacodesReleased: [],
            // selectedPicacodesReleased: []
        }
    },

    methods: {
        setPicacodesReleased(value) {
            console.log('setPicacodesReleased', value)
            //this.selectedPicacodesReleased.push(value)
            this.$emit('input', value)
        },

        getPicacodesReleased(query) {
            console.log('getPicacodeReleased query', query)

            let params = {}
            const isRaceNumber = query.substr(0, 1) === ':'
            if (isRaceNumber)
                params.raceNumber = query.substr(1)
            else
                params.picacode = query

            return PicaServiceService.getPicacodeReleased(this.$store.getters['event/picaServiceId'], params)
                .then((response) => {
                    console.log('getPicacodeReleased response', response.data)
                    this.picacodesReleased = response.data.results
                })
        },

        emptyOptions() {
            this.picacodesReleased = []
        }
    }
}
</script>